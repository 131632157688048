const SectionCollaborazioni = (props) => {
  const logos = [
      "./images/Logo_Elsag_Datamat.png",
      "./images/Logo_EFSA.png",
      "./images/Logo_Dvel.png",
      "./images/Logo_Dsign.png",
      "./images/Logo_Engineering.png",
  ];

  const logos_mob1 = [
      "./images/Logo_EFSA.png",
      "./images/Logo_Dvel.png",
      "./images/Logo_Dsign.png",
  ];
  const logos_mob2 = [
      "./images/Logo_Elsag_Datamat.png",
      "./images/Logo_Engineering.png",
  ];

  return(
    <div className="section-container">
      <div className="section-content">
        <div className="flex-container">
          <div className="flex-item">
            Collaborazioni:
          </div>

          <div className="flex-container-loghi">
          {logos.map((image, i) => (
            <div className="flex-item-loghi" key={i}>
              <img
                  key={i}
                  src={image}
                  alt="Logo"
              />
            </div>
          ))}
          </div>


          {/* MOBILE */}
          <div className="flex-container-loghi mobile">
          {logos_mob1.map((image, i) => (
            <div className="flex-item-loghi" key={i}>
              <img
                  key={i}
                  src={image}
                  alt="Logo"
              />
            </div>
          ))}
          </div>
          <div className="flex-container-loghi mobile">
          {logos_mob2.map((image, i) => (
            <div className="flex-item-loghi" key={i}>
              <img
                  key={i}
                  src={image}
                  alt="Logo"
              />
            </div>
          ))}
          </div>

        </div>
      </div>
    </div>
  );
}

export default SectionCollaborazioni
