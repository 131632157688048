const SectionArnesi = (props) => {
  const arnesi = [
    "./images/arnesi/Html5.png",
    "./images/arnesi/Css3.png",
    "./images/arnesi/Sass.png",
    "./images/arnesi/Bootstrap.png",
    "./images/arnesi/Javascript.png",
    "./images/arnesi/Es6.png",
    "./images/arnesi/React.png",
    "./images/arnesi/Jquery.png",
    "./images/arnesi/Nodejs.png",
    "./images/arnesi/Animejs.png",
    "./images/arnesi/Drupal.png",
    "./images/arnesi/Php8.png",
    "./images/arnesi/Mysql.png",
  //"./images/arnesi/Sqlite.png",
    "./images/arnesi/Json.png",
    "./images/arnesi/Firebase.png",
    "./images/arnesi/Phpstorm.png",
    "./images/arnesi/Git.png",
    "./images/arnesi/Twig.png",
  ];
  return(
    <div className="section-container">
      <div className="section-content">
        <h2>Gli arnesi del mestiere.
        <span className="turquoise"> Per ora.</span></h2>
        <div className="flex-container">
        {arnesi.map((image, i) => (
          <div className="flex-item" key={i}>
            <img
              key={i}
              src={image}
              alt="Logo"
            />
          </div>
        ))}
        </div>
        Al momento sto studiando: <em>React, CSS3, AnimeJs</em>
      </div>
    </div>
  );
}

export default SectionArnesi
