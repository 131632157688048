const SectionChiSono = (props) => {
  return(
    <div className="section-container">
      <div className="section-content">
        <div className="flex-container">
          <div className="flex-item">
            <h2 id="chisono-title" className="animatethis">Consulente freelance<br/>
            per web agencies<br/> e privati.<br/>
            <span className="turquoise">Dal 2006</span></h2>
            <h2 id="chisono-title" className="animatethis mobile">Consulente freelance<br/>
            per web agencies e privati.<br/>
            <span className="turquoise">Dal 2006</span></h2>
          </div>
          <div id="chisono-text" className="flex-item text animatethis">
            Nato e cresciuto nella periferia ovest di Bologna, mi sono
            trasferito nelle <a href="https://www.google.it/maps/dir//Pegognaga,+46020+MN/@44.9956008,10.8516503,15z/" 
            target="_blank" rel="noreferrer">campagne della bassa mantovana</a> per amore.&nbsp;
            <img src="./images/Love.png" alt="" /><br/>
            Operativo nello sviluppo web dal 1999, lavoro in smart working
            da oltre dieci anni. La mia quotidianità è fatta di centinaia di
            righe di codice, caffè, call organizzative, e tanta musica in sottofondo
            (non durante le call).
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionChiSono
