import React, {useState} from 'react';
import Masonry from "react-responsive-masonry";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faBullseye, faChevronLeft, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import fotografia from '../img/BtnFotografia.png';
import alpinismo from '../img/BtnAlpinismo.png';
import knifemaking from '../img/BtnKnifemaking.png';

const Fotografia = (props) => {

  const images = [
      "./images/interessi/foto/DSC00922-3.jpg",
      "./images/interessi/foto/DSC05430.jpg",
      "./images/interessi/foto/DSC05490.jpg",
      "./images/interessi/foto/DSCF7660.jpg",
      "./images/interessi/foto/DSCF0099-3.jpg",
      "./images/interessi/foto/DSCF6812-2.jpg",
      "./images/interessi/foto/DSCF1303-2.jpg",
      "./images/interessi/foto/DSCF1944-3.jpg",
      "./images/interessi/foto/DSCF2297.jpg",
      "./images/interessi/foto/DSCF6868.jpg",
      "./images/interessi/foto/DSCF2935.jpg",
      "./images/interessi/foto/DSCF6980-2.jpg",
      "./images/interessi/foto/DSCF2961.jpg",
      "./images/interessi/foto/DSCF6327.jpg",
      "./images/interessi/foto/DSCF3011-3.jpg",
      "./images/interessi/foto/DSCF3203-2.jpg",
      "./images/interessi/foto/DSCF3413-2.jpg",
      "./images/interessi/foto/DSCF3473.jpg",
      "./images/interessi/foto/DSCF4195.jpg",
      "./images/interessi/foto/DSCF5663.jpg",
      "./images/interessi/foto/DSCF6200.jpg",
      "./images/interessi/foto/DSCF6245.jpg",
  ]

  return(
    <>
      <h2>Fotografia</h2>
      <p>
        <a href="https://www.instagram.com/lumina_rebus" className="ig-link"
          target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faInstagram} /> lumina_rebus
        </a>
      </p>
      <div className="intro">
        <FontAwesomeIcon icon={faCamera} /><em> Fujifilm X-T2</em><br/>
        <FontAwesomeIcon icon={faBullseye} /><em> Fujinon XF16mm f1.4 R WR </em>
        <FontAwesomeIcon icon={faBullseye} /><em> Fujinon XF35mm f1.4 R </em>
        <FontAwesomeIcon icon={faBullseye} /><em> Fujinon XF10-24mm f4 R OIS</em>
      </div>
      <Masonry columnsCount={4} gutter="10px">
        {images.map((image, i) => (
          <img
            key={i}
            src={image}
            alt=""
            style={{width: "100%", display: "block"}}
          />
        ))}
      </Masonry>
    </>
  );
}



const Alpinismo = (props) => {

  const images = [
      "./images/interessi/alp/IMG_5124.jpg",
      "./images/interessi/alp/bletter2.jpg",
      "./images/interessi/alp/DSC05578.jpg",
      "./images/interessi/alp/DSC05588-2.jpg",
      "./images/interessi/alp/DSC05598.jpg",
      "./images/interessi/alp/IMG_8427.jpg",
      "./images/interessi/alp/bletter1.jpg",
      "./images/interessi/alp/DSC05602.jpg",
      "./images/interessi/alp/DSCF1291-3.jpg",
      "./images/interessi/alp/IMG_5128.jpg",
      "./images/interessi/alp/IMG_5133.jpg",
      "./images/interessi/alp/DSCF2449-2.jpg",
      "./images/interessi/alp/DSCF2558-2.jpg",
      "./images/interessi/alp/marmolada.jpg",
      "./images/interessi/alp/falesia1.jpg",
      "./images/interessi/alp/IMG_5093.jpg",
      "./images/interessi/alp/IMG_5105.jpg",
      "./images/interessi/alp/IMG_5118.jpg",
      "./images/interessi/alp/paterno.jpg",
      "./images/interessi/alp/IMG_5138.jpg",
      "./images/interessi/alp/IMG_8420-2.jpg",
      "./images/interessi/alp/inverno1.jpg",
  ]

  return(
    <>
      <h2>Alpinismo</h2>
      <div className="intro">
        In montagna dall'età di 5 anni, pratico escursionismo estivo e
        invernale, alpinismo e arrampicata (falesia e multipitch)
      </div>
      <Masonry columnsCount={4} gutter="10px">
        {images.map((image, i) => (
          <img
            key={i}
            src={image}
            alt=""
            style={{width: "100%", display: "block"}}
          />
        ))}
      </Masonry>
    </>
  );
}



const Knifemaking = (props) => {

  const images = [
      "./images/interessi/knf/42669438.jpg",
      "./images/interessi/knf/45582208.jpg",
      "./images/interessi/knf/69621914.jpg",
      "./images/interessi/knf/70282162.jpg",
      "./images/interessi/knf/70511839.jpg",
      "./images/interessi/knf/70985617.jpg",
      "./images/interessi/knf/71153828.jpg",
      "./images/interessi/knf/73066647.jpg",
      "./images/interessi/knf/73401861.jpg",
      "./images/interessi/knf/74341844.jpg",
      "./images/interessi/knf/93610172.jpg",
      "./images/interessi/knf/94427415.jpg",
      "./images/interessi/knf/96212753.jpg",
      "./images/interessi/knf/96360334.jpg",
      "./images/interessi/knf/97261429.jpg",
      "./images/interessi/knf/97286883n.jpg",
      "./images/interessi/knf/97538652.jpg",
      "./images/interessi/knf/103983800.jpg",
      "./images/interessi/knf/106422970.jpg",
  ]

  return(
    <>
      <h2>Coltelli artigianali</h2>
      <p>
        <a href="https://www.instagram.com/fabbriknives" className="ig-link"
          target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faInstagram} /> fabbriknives
        </a>
      </p>
      <div className="intro">
        Una passione che ha origini lontane, quasi primordiali.<br/>
        Le mie lame sono ormai all'opera nelle cucine di diversi Paesi, dalla
        Germania agli Stati Uniti.
      </div>
      <Masonry columnsCount={4} gutter="10px">
        {images.map((image, i) => (
          <img
            key={i}
            src={image}
            alt=""
            style={{width: "100%", display: "block"}}
          />
        ))}
      </Masonry>
    </>
  );
}


const SectionInteressi = (props) => {

  const [contentToShow, setContentToShow] = useState();
  const [activeSubPage, setActiveSubPage] = useState();

  return(
    <>
    <div className="section-container">
      <div className="section-content">
        <div className="flex-container">
          <div className="flex-item intro">
            <h2>DALLA <span className="turquoise">FOTOGRAFIA </span>
            ALLA PRODUZIONE DI <span className="turquoise">COLTELLI DA CUCINA
            ARTIGIANALI</span>, PASSANDO PER L'
            <span className="turquoise">ALPINISMO</span>.<br/>
            (dai, che forse non sono 100% nerd...)</h2>
          </div>
          <div className="flex-item">
            <div className="layerbox-open" onClick={() => {setContentToShow(<Fotografia />);
            setActiveSubPage("fotografia");}}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
          </div>
        </div>
        <div id="interessi-layerbox">
          <div className="header">
            <FontAwesomeIcon icon={faWindowClose} className="layerbox-close" />
            <img src="./images/Logo.svg" className="logo" alt="" />
          </div>
          <div className="content">
          <h2>Gli altri miei <span className="turquoise">interessi</span>.</h2>
          <h4>Grazie per aver aperto questa sezione!<br/>
          Contiene cose a cui tengo molto...</h4>
          <div className="btn-triggers-container">
            <img src={fotografia} alt="Fotografia in bianco e nero"
            onClick={() => {setContentToShow(<Fotografia />); setActiveSubPage("fotografia");}}
            className={"trigger trigger-foto" + (activeSubPage === "fotografia" ? " active" : "")} />
            <img src={alpinismo} alt="Alpinismo e arrampicata"
            onClick={() => {setContentToShow(<Alpinismo />); setActiveSubPage("alpinismo");}}
            className={"trigger trigger-alp" + (activeSubPage === "alpinismo" ? " active" : "")} />
            <img src={knifemaking} alt="Produzione coltelli da cucina artigianali"
            onClick={() => {setContentToShow(<Knifemaking />); setActiveSubPage("knifemaking");}}
            className={"trigger trigger-knv" + (activeSubPage === "knifemaking" ? " active" : "")} />
          </div>
          {contentToShow}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default SectionInteressi
