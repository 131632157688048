import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPauseCircle, faPlayCircle } from '@fortawesome/free-regular-svg-icons';

const SectionFeedback = (props) => {
  return(
    <div className="section-container">
      <div className="section-content">
        <div className="flex-container">
          <div className="flex-item">
            <h2>Ho sentito <span className="turquoise">persone</span><br/>
            sparlarmi dietro!
            <img src="./images/Palledegliocchi.png" className="palledegliocchi" alt="" />
            </h2>
            <h2 className="mobile">Ho sentito <span className="turquoise">persone</span> sparlarmi dietro!
            <img src="./images/Palledegliocchi.png" className="palledegliocchi" alt="" />
            </h2>
          </div>
          <div className="flex-item">
            <div className="controls">
              <div>
                <FontAwesomeIcon icon={faPauseCircle} />
                <FontAwesomeIcon icon={faPlayCircle} />
              </div>
              <input className="progress" step=".001" type="range" min="0" max="100" value="0" />
            </div>
            <div className="feedback-container">
              <div className="feedback">
                <h4>Gabriele worked as a
                consultant in the Biological Monitoring Unit of the Europen Food
                Safety Authority (EFSA) between December 2006 and December 2011.
                During this period he contributed in the coordination of annual
                reporting of data on zoonoses, antimicrobial resistnace and
                food-borne outbreaks by the EU Member States. This included
                running a reporting helpdesk, data validation and testing of the
                reporting application. He also participated in the preparation
                of the related analyses of the data at European Union level.
                Gabriele was well motivated, hard working and carried out of all
                his tasks at high quality level. He was also a good team player
                and was very much appreciated by his colleagues in the unit.</h4>
                <h5><strong>Pia Makela</strong><br/>
                Head of Unit on Biological Monitoring, EFSA</h5>
              </div>
              <div className="feedback">
                <h4>Gabriele and I worked at a scientific database project at the
                European Food Safety Authority between February 2007 and March 2008.
                I was in charge of the software development team. Gabriele ultimately
                led the beta testing team. We worked together closely to analyze
                the users' needs, decipher the scientists' requests, and identify
                and diagnose bugs. Our cooperation led to a rapid and effective
                problem resolution process, resulting in a high level of
                satisfaction by our users.<br/>
                <br/>
                I would like to recommend Gabriele Fabbri as a skilled IT
                professional with a knack for communicating with users and keeping
                everyone cool in the face of trouble.</h4>
                <h5><strong>Alessandro Baretta </strong><br/>
                IT Senior Consultant, EFSA</h5>
              </div>
              <div className="feedback">
                <h4>Ho avuto modo di collaborare con Gabriele per il
                progetto web di Verona Film Commission e io sono stato
                piacevolmente sorpreso dall'affidabilità, professionalità e
                accuratezza sia nella progettazione del codice che nei dettagli
                del lavoro finito (quali la grafica e le interfacce usabili).
                Se dovessi sintetizzare in un solo termine “qualità” è la
                caratteristica che rappresenta al meglio il lavoro di Gabriele.
                Lo consiglio vivamente!</h4>
                <h5><strong>Matteo Massimo Santoni</strong><br/>
                IT Specialist, Gruppo Vason</h5>
              </div>
              <div className="feedback">
                <h4>Gabriele is a very strong detail-oriented consultant who
                is always focus on the general purpose of its work without
                loosing sight of the strategic objective. Besides this, he is
                trustable and hard worker.</h4>
                <h5><strong>Gianni Contestabile</strong><br/>
                Executive Assistant, EFSA</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionFeedback
