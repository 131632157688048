import React from 'react';
import SectionInteressi from './components/SectionInteressi';
import SectionHome from './components/SectionHome';
import SectionChiSono from './components/SectionChiSono';
import SectionCollaborazioni from './components/SectionCollaborazioni';
import SectionCosaFaccio from './components/SectionCosaFaccio';
import SectionArnesi from './components/SectionArnesi';
import SectionFeedback from './components/SectionFeedback';
import SectionFaq from './components/SectionFaq';
import Footer from './components/Footer';
import CookieConsent from "react-cookie-consent";

import 'bootstrap/dist/css/bootstrap.min.css';
import './css/App.css';
import './css/AppMediaQueries.css';

const App = (props) => {
  return(
    <div id="main">
      <section id="home">
        <SectionHome />
      </section>
      <section id="chi-sono">
        <SectionChiSono />
      </section>
      <section id="collaborazioni">
        <SectionCollaborazioni />
      </section>
      <section id="cosa-faccio">
        <SectionCosaFaccio />
      </section>
      <section id="arnesi">
        <SectionArnesi />
      </section>
      <section id="feedback">
        <SectionFeedback />
      </section>
      <section id="interessi">
        <SectionInteressi />
      </section>
      <section id="faq">
        <SectionFaq />
      </section>
      <footer>
        <Footer />
      </footer>

      <CookieConsent
        location="bottom"
        buttonText="Benissimo!"
        cookieName="biscottoDiGabriele"
        acceptOnScroll="true"
        acceptOnScrollPercentage="15"
        style={{ background: "#333333" }}
        buttonStyle={{ color: "#ffffff", backgroundColor: "#009E9A",fontSize: "13px" }}
        expires={150}
      >
        Questo sito utilizza cookies per migliorare la tua esperienza di navigazione.
      </CookieConsent>


    </div>
  );
}

export default App
