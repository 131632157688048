import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleUp } from '@fortawesome/free-regular-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Year = () => {
  let d = new Date();
  return(d.getFullYear());
}

const Footer = (props) => {
  return(
    <div className="section-container">
      <div className="section-content">
        <div>
          <img src="./images/Logo.svg" className="logo" alt="" />
        </div>
        <div className="flex-container">
          <div className="flex-item">
            <h3>GABRIELE FABBRI</h3>
            <h2>Sviluppatore web.<br/>
            Full Stack.</h2>
            P.iva: 02663011209<br/>
            &copy; <Year /> Gabriele Fabbri
          </div>
          <div className="flex-item links">
            <span>Vuoi rileggere qualcosa?</span>
            <a href="#chi-sono">&raquo; Consulente, programmatore freelance</a><br/>
            <a href="#cosa-faccio">&raquo; Posso essere d'aiuto?</a><br/>
            <a href="#arnesi">&raquo; Gli arnesi del mestiere</a><br/>
            <a href="#feedback">&raquo; Ho sentito persone sparlarmi dietro</a><br/>
            <a href="#interessi">&raquo; Altri miei interessi personali</a><br/>
            <a href="#faq">&raquo; Hai delle domande?</a><br/>
          </div>
          <div className="flex-item social">
            <a href="#main" className="to-top">
              <FontAwesomeIcon icon={faArrowAltCircleUp} />
            </a><br/>
            <a href="https://www.instagram.com/lumina_rebus" className="ig-link"
              target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faInstagram} /> lumina_rebus
            </a><br/>
            <a href="https://www.instagram.com/fabbriknives" className="ig-link"
              target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faInstagram} /> fabbriknives
            </a><br/>
            <FontAwesomeIcon icon={faLinkedin}
              onClick={() => {
                window.open('https://www.linkedin.com/in/gabrielefabbri/', '_blank');
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer
