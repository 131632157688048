import BtnMailTo from './BtnMailTo';
import Accordion from 'react-bootstrap/Accordion';

const SectionFaq = (props) => {
  return(
    <div className="section-container">
      <div className="section-content">
        <h2>Hai <span className="turquoise">domande</span>?<br/>
        Aspetta, perché potrei avere la risposta pronta.</h2>
        <div className="flex-container">
          <div className="flex-item">
            <Accordion flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Se ti scrivo succede qualcosa di brutto?</Accordion.Header>
                <Accordion.Body>
                  Niente paura, il massimo che potrà capitare è che risponda (al più presto).<br/>
                  Scrivimi senza remore, anche solo per un saluto :)
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Come funziona il tutto?</Accordion.Header>
                <Accordion.Body>
                  Questa è una bella domanda!<br/>
                  il seguente metodo è applicabile con successo al 90% dei progetti:
                  <ol>
                    <li>
                      <strong>Raccolta dei requisiti</strong><br/>
                      Ascolto (e scrivo) con attenzione le richieste
                      estetico/funzionali del progetto.
                    </li>
                    <li>
                      <strong>Analisi progettuale</strong><br/>
                      Stesura di un documento di analisi tecnico/funzionale del
                      lavoro, in cui verranno illustrate le soluzioni scelte per
                      soddisfare i requisiti raccolti.
                    </li>
                    <li>
                      <strong>Preventivo</strong><br/>
                      L'analisi progettuale definisce il lavoro da svolgere nella
                      sua interezza e permette di stimarne il costo.<br/>
                      L'eventuale accettazione del preventivo darà il via allo
                      sviluppo del progetto.
                    </li>
                    <li>
                      <strong>Sviluppo</strong><br/>
                      La fase di sviluppo effettivo è al tempo stesso la parte
                      più dura e più entusiasmante.<br/>
                      E' sempre mia premura tenerti informato sull'andamento
                      dei lavori e mi confronto regolarmente in caso di dubbi o
                      domande (reciproche).
                    </li>
                    <li>
                      <strong>Rilascio</strong><br/>
                      Bene! Sviluppo terminato, possiamo pubblicare tutto!<br/>
                      E invece no, perché una delle fasi che molti trascurano è
                      quella di test e debug.<br/>
                      Prima di rilasciare un progetto testo accuratamente il codice
                      scritto, correggendo eventuali problemi. E' una fase
                      molto importante, che spesso la fretta porta a sottovalutare.
                    </li>
                  </ol>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Quanto mi costi?</Accordion.Header>
                <Accordion.Body>
                  Questo dipende sempre da cosa serve (requisiti).<br/>
                  Che si tratti di un sito completo, o di un componente da
                  integrare, o altro, partiremo sempre con un'analisi
                  tecnico/funzionale dettagliata.<br/>
                  Il mio preventivo di spesa è gratuito e non è vincolante fino
                  alla sua accettazione scritta.<br/>
                  Se avete un budget già definito per il lavoro benvenga, sarà
                  un buon punto di partenza per considerare insieme l'aspetto
                  economico.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Quanto tempo ci vuole?</Accordion.Header>
                <Accordion.Body>
                  I tempi sono definiti principalmente da due fattori:<br/>
                  <ol>
                    <li>la complessità del lavoro</li>
                    <li>la mia disponibilità e il tempo quotidiano che posso dedicargli</li>
                  </ol>
                  E' sempre mia massima premura valutare attentamente ogni
                  progetto in termini di fattibilità, anche a costo di
                  non prenderlo a carico, a malincuore.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Lavori da remoto?</Accordion.Header>
                <Accordion.Body>
                  Si, lavoro in smart working da oltre dieci anni, sempre con
                  ottimi risultati.<br/>
                  Sono automunito e non ho problemi a presenziare ad incontri
                  conoscitivi o meeting in sede, quando necessario. Ho
                  sempre lo smartphone a portata di mano e mi avvalgo quotidianamente
                  di strumenti come Google Meet, o simili, per coordinare i lavori.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>Fai tutto da solo?</Accordion.Header>
                <Accordion.Body>
                  Generalmente sono in grado di gestire interamente ogni fase dei lavori che
                  accetto, inserendomi anche in team di sviluppo, se presenti.<br/>
                  Per alcuni progetti, se è richiesta l'elaborazione di un
                  layout grafico particolarmente "ricco", potrei appoggiarmi
                  ad un collaboratore di mia fiducia.<br/>
                  Stesso discorso per eventuali pratiche SEO e web/social marketing.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>Mi hai fatto un sito stupendo, ora dove lo metto?</Accordion.Header>
                <Accordion.Body>
                  Non fornisco direttamente servizio di hosting, ma posso
                  consigliarti diversi providers affidabili e organizzare
                  insieme la pubblicazione del sito.<br/>
                  E' importante ricordare che lo spazio di hosting e il
                  relativo dominio (l'indirizzo URL a cui risponde il sito),
                  saranno di tua proprietà (e responsabilità).
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>Ti piace Wordpress?</Accordion.Header>
                <Accordion.Body>
                  Effettivamente <a href="https://www.drupal.org/" target="_blank"
                  rel="noreferrer">DRUPAL</a> è un vero CMS, basato sul monumentale Symfony framework,
                  nativamente modulare, potente e con politiche molto strict anche sulla
                  qualità di moduli creati da terzi... ;)
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>Posso contare su di te anche a lavoro terminato?</Accordion.Header>
                <Accordion.Body>
                  Assolutamente si!<br/>
                  I miei lavori sono coperti da "garanzia" di manutenzione
                  ordinaria in caso di bug o problemi riconducibili ad un mio
                  errore (nessuno è perfetto).<br/>
                  Interventi volti ad implementare nuove funzionalità o modifiche,
                  che differiscono dall'analisi progettuale originale,
                  saranno soggette ad un nuovo preventivo di spesa.<br/>
                  Detto questo, se non abitiamo troppo distanti, puoi contare
                  su di me anche per bere due birre ogni tanto :)
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>Perché costruisci coltelli da cucina?</Accordion.Header>
                <Accordion.Body>
                  E' una lunga storia, che ha portato alla nascita di una grande passione.<br/>
                  Scrivimi, sarò felice di raccontartela :)
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="flex-item">
            <BtnMailTo elId="cta-ciao-2" placement="bottom"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionFaq
