import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const SectionHome = (props) => {
  const copyToClipboard = () => {
    setTimeout(async () => await navigator.clipboard.writeText('gabrielefabbri@gmail.com'), 3000);
    alert("Indirizzo email copiato in memoria.\nFanne buon uso... :)");
  }
  return (
    <div className="section-container">
      <div className="top flex-container">
        <div className="flex-item">
          <img src="./images/Logo.svg" className="gf-logo" alt="" />
        </div>
        <div className="flex-item">
          <FontAwesomeIcon icon={faLinkedin}
            onClick={() => {
              window.open('https://www.linkedin.com/in/gabrielefabbri/', '_blank');
            }}
          />
          <FontAwesomeIcon icon={faEnvelope}
            onClick={() => { copyToClipboard(); }}
          />
        </div>
      </div>
      <div className="section-content">
        <div className="intro-container">
          <h3>GABRIELE FABBRI</h3>
          <h1 className="main-title">
            <span className="text-wrapper">
              <span className="letters">Sviluppatore web.</span>
            </span>
          </h1>
          <h1 className="main-title">
            <span className="text-wrapper">
              <span className="letters">Full-stack.</span>
            </span>
          </h1>
          <h2><span className="main-title-sub-1 turquoise">Freelance.</span><span className="main-title-sub-2"> Italia.</span></h2>
        </div>

        {/* MOBILE */}
        <div className="intro-container mobile">
          <h3>GABRIELE FABBRI</h3>
          <h1 className="main-title-mob">Sviluppatore web. <br />
            Full-stack.</h1>
          <h2><span className="main-title-mob-sub-1 turquoise">Freelance.</span><span className="main-title-mob-sub-2"> Italia.</span></h2>
        </div>

        <a href="./Gabriele-Fabbri-CV.pdf" target="_blank">
          <img src="./images/Download_CV.png" className="cv" alt="Gabriele Fabbri Curriculum Vitae download PDF" />
          <img src="./images/Avatar_200.png" className="avatar" alt="Gabriele Fabbri, programmatore web freelance Italia" />
        </a>
      </div>
    </div>
  );
}

export default SectionHome
