import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

const BtnMailTo = (props) => {
  const mailToStandard = () => {
    window.location.href = "mailto:gabrielefabbri@gmail.com?subject=Ciao Gabriele!";
  }
  const copyToClipboard = () => {
    setTimeout(async()=>await navigator.clipboard.writeText('gabrielefabbri@gmail.com'), 3000);
    alert("Indirizzo email copiato in memoria.\nFanne buon uso... :)");
  }
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <button className="btn-mailto" onClick={() => {mailToStandard();}}>Apri il tuo client di posta</button>
        <button className="btn-copyto" onClick={() => {copyToClipboard();}}>Copia indirizzo email</button>
      </Popover.Body>
    </Popover>
  );
  return(
    <OverlayTrigger trigger="click" placement={props.placement} overlay={popover}>
      <Button className="cta-ciao" id={props.elId}>
        <FontAwesomeIcon icon={faEnvelope} /> CIAO GABRIELE!
      </Button>
    </OverlayTrigger>
  );
}

export default BtnMailTo
