import BtnMailTo from './BtnMailTo';

const CardBox = (props) => {
  return(
    <div className="cardbox flex-item" style={{backgroundColor:props.cardContent.bgcolor}}>
      <div className="turquoise-line"></div>
      <div className="cardbox-title"><h3>{props.cardContent.title}</h3></div>
      <div className="cardbox-body">{props.cardContent.body}</div>
    </div>
  );
}

const SectionCosaFaccio = (props) => {
  const cards = [
    {
      title: "Drupal CMS",
      body: [`Cinque anni di esperienza quotidiana con `,<a href="https://www.drupal.org/"
      target="_blank" rel="noreferrer">Drupal CMS</a>,` (7~9) per
      lo sviluppo di portali interattivi ad alto volume di utenza per la PA e privati;
      moduli custom, integrazioni avanzate con sistemi di terze parti. Web app per specifiche esigenze.`],
      bgcolor: "#EAEFF5",
    },
    {
      title: "Siti responsive",
      body: [`Impiego di HTML5 e CSS3 per design incisivi ed efficaci.
      Cura maniacale dei dettagli e grande attenzione alla resa finale su
      qualsiasi device, desktop e mobile, per una user experience di forte impatto,
      ma sempre funzionale.`],
      bgcolor: "#EEF5EA",
    },
    {
      title: "Web app e UI",
      body: [`Buona conoscenza di JavaScript (anche `,<a href="https://262.ecma-international.org/6.0/"
      target="_blank" rel="noreferrer">ES6</a>,`) e `,<a href="https://jquery.com/"
      target="_blank" rel="noreferrer">jQuery.</a>,<br/>,`Uso `,
      <a href="https://reactjs.org/"
      target="_blank" rel="noreferrer">React Js</a>,` per realizzare web app e componenti UI interattivi;
      libreria che in poco tempo ha rivoluzionato lo sviluppo
      front-end in molte aziende del settore.`],
      bgcolor: "#FFFAEA",
    },
    {
      title: "Lato server",
      body: `Diciotto anni di esperienza su PHP/MySQL.
      Integrazione dati o funzionalità da sistemi terzi:
      creazione e consumo RESTful WS, SSO CAS e integrazioni Drupal/Liferay
      per la PA e portali aziendali multicountry e multilingua.`,
      bgcolor: "#FFF5F5",
    },
  ];

  return(
    <div className="section-container">
      <div className="section-content">
        <h2>Posso essere d'<span className="turquoise">aiuto</span>?</h2>
        <div id="cards-container" className="flex-container animatethis">
          {cards.map((card, key) => (
            <CardBox key={key} cardContent={card} />
          ))}
        </div>
        <BtnMailTo elId="cta-ciao-1" placement="top"/>
      </div>
    </div>
  );
}

export default SectionCosaFaccio
